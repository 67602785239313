import { action, makeObservable, observable } from "mobx"

class CameraStore {

  stream: MediaStream | null = null
  status: "init" | "loaded" | "reject" = "init"

  constructor() {
    makeObservable(this, {
      status: observable
    })
  }

  init() {
    if (this.status === "loaded") return
    navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: 'environment' }})
      .then(action(stream => {
        this.stream = stream
        this.status = "loaded"
      }))
      .catch(action(() => {
        this.status = "reject"
      }))
  }

}

export default CameraStore