import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import styles from './style.module.sass'
import { BsDiamondFill } from 'react-icons/bs'
import { useMainStore } from 'store'
import { useTrail, useTransition, animated, config } from 'react-spring'

const GettingStartedPage = observer(() => {
  const { loader } = useMainStore()
  const texts = [
    "Тест видео",
    "Нажми на видео, чтобы оно прикрепилось к экрану"
  ]
  const textsProps = useTrail(texts.length, { 
    from: { x: -100, opacity: 0 }, 
    to: { x: 0, opacity: 1 },
    config: config.stiff
  })
  const animStatus = useTransition(loader.status, {
    enter: { scale: 1, opacity: 1 },
    from: { scale: 0.5, opacity: 0 }, 
    leave: { scale: 0.5, opacity: 0 },
    config: config.wobbly
  })

  let h1Text = "Наведи камеру на упаковку и получи заряд настроения прямиком из Бразилии!"
  if( window.innerWidth >= 1000 ) {
    h1Text = `Упс!.. К сожалению, с компьютера дополненную реальность не увидеть. Сканируйте QR-код с Вашего мобильного телефона`
  }


  return (
    <div className={cn(styles.container, 'h')}>
      <img src={require("./logo.png")} className={styles.img}/>
      <h1>{h1Text}</h1>
      <img src={require("./qr-code.svg").default} className={styles.imgdesk}/>
      <div className="anim-wrapper" style={{ height: "50px" }}>
        { animStatus((style, status) => {
          if (status === "init") return (
            <animated.div style={style}>Идет загрузка...</animated.div>
          )
          if (status === "error") return (
            <animated.div style={style}>Произошла ошибка</animated.div>
          )
          if (status === "loaded") return (
            <animated.button onClick={() => loader.continue()} style={style} className={styles.btn}>
              Доставить солнце
            </animated.button>
          )
        })}
      </div>
    </div>
  )
})

export default GettingStartedPage
