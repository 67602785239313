import styles from './style.module.sass'
import cn from 'classnames'
import { IoCameraOutline } from 'react-icons/io5'

const CameraRejectedPage = () => {

  return (
    <div className={cn(styles.container, 'h')}>
      <img src={require("./logo.png")} className={styles.img}/>
      <div className="stack">
        <IoCameraOutline fontSize="3em"/>
        <div>Для того, чтобы начать, разрешите сайту доступ к камере</div>
      </div>
    </div>
  )
}

export default CameraRejectedPage
