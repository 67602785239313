import ArStore from "./ar"
import CameraStore from "./camera"
import RenderStore from "./render"
import SceneLoaderStore from "./scene-loader"

class MainStore {

  loader = new SceneLoaderStore(this)
  camera = new CameraStore()
  ar = new ArStore(this)
  render = new RenderStore(this)

  constructor() {

  }

  init() {
    this.camera.init()
    this.loader.init()
    this.ar.init()
  }

  dispose() {
    
  }

}

export default MainStore