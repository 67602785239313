import { useEffect, useState } from 'react'
import { animated, config, useTransition } from 'react-spring'
import crossPng from './cross.png'
import infoPng from './info.png'
import styles from './style.module.sass'
import Dialog from 'components/dialog'
import { useMainStore } from 'store'

const SceneUI = () => {

  const { render } = useMainStore()
  const [ showInfo, setShowInfo ] = useState(false)
  const [ showVideo, setShowVideo ] = useState(false)

  const transitions = useTransition(showInfo, {
    from: { scale: 0.3, opacity: 0 },
    enter: { scale: 1, opacity: 1 },
    leave: { scale: 0.3, opacity: 0 },
    config: config.stiff
  })
  
  useEffect(() => {
    setTimeout(() => {
      render.scene.addEventListener("onButtonClick", () => {
        setShowVideo(true)
      })
    }, 200)
  }, [])

  const onClose = () => {
    render.scene.dispatchEvent({ type: "onDialogClose" })
    setShowVideo(false)
  }

  return (
    <>
      { transitions((style, mode) => (
        <animated.button 
          className={styles.btn} 
          style={{ ...style, backgroundImage: `url(${ mode? crossPng: infoPng })` }} 
          onClick={() => setShowInfo(i => !i)}
        >
        </animated.button>
      ))}
      <Dialog className={styles.dialog} open={showInfo} onClose={() => setShowInfo(false)}>
        <div>
* E.A. Пигарова, Л.Я. Рожинская, Ж.Е. Белая, Л.К. Дзеранова, Т.Л. Каронова, А.В. Ильин, Г.А. Мельниченко, И.И. Дедов
«Клинические рекомендации Российской ассоциации эндокринологов по диагностике лечению и профилактике дефицита витамина D у взрослых»
<br/>
// Проблемы эндокринологии.
          <br/>
            2016 — 4 — С.60-84
          <br/>
          <br/>
          ** компания "Бенес Хелскеа" имеет производство в 5 странах мира
        </div>
      </Dialog>

      <Dialog className={styles.videoDialog} open={showVideo} onClose={onClose}>
        <video src="./assets/video.mp4" autoPlay playsInline/>
      </Dialog>
      
      {/* <div className={styles.disclaimer}> 
      <span>Биологически активная добавка к пище. Не является лекарственным средством</span>
      </div> */}
    </>
  )
}

export default SceneUI
