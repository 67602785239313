import { action, makeObservable } from 'mobx'
import MainStore from 'store/store'
import * as THREE from 'three'
import { findRecursive } from 'utils/mesh'

class RenderStore {

  mirror = false
  renderer!: THREE.WebGLRenderer
  scene2D!: THREE.Scene
  scene3D!: THREE.Scene

  canvas!: HTMLCanvasElement
  videoTexture!: THREE.Texture
  videoSprite!: THREE.Sprite

  camera2D!: THREE.OrthographicCamera
  camera3D!: THREE.PerspectiveCamera

  scene!: THREE.Scene

  private mainStore: MainStore
  constructor(mainStore: MainStore) {
    this.mainStore = mainStore
    makeObservable(this, {
      loop: action.bound,
      setCanvasRef: action.bound,
      onResize: action.bound,
      onPostResize: action.bound
    })
  }

  init() {

    const defaultWidth = 300;
    const defaultHeight = 300;
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, alpha: true, antialias: true });

    this.renderer.setSize(defaultWidth, defaultHeight, false);
    this.renderer.setClearColor(0x000000, 0.5);
    this.renderer.autoClear = false;
    this.renderer.setPixelRatio(2);

    // Create scene
    this.scene2D = new THREE.Scene();
    this.scene3D = new THREE.Scene();

    // Setup cameras
    this.camera2D = new THREE.OrthographicCamera(
      -defaultWidth / 2,
      defaultWidth / 2,
      defaultHeight / 2,
      -defaultHeight / 2, 1, 10);
    this.camera2D.position.z = 10;
    this.scene2D.add(this.camera2D);

    this.camera3D = new THREE.PerspectiveCamera(60, defaultWidth / defaultHeight, 0.04, 100);
    this.camera3D.position.set(0, 0, 0);
    this.scene3D.add(this.camera3D);
    this.scene = this.mainStore.loader.scene
    this.scene3D.add(this.scene);
    this.scene.dispatchEvent({ type: "addToRenderer", renderer: this.renderer, scene: this.scene3D })
    // (this.scene as any).emit("addToRenderer", this.renderer)

    // Create video texture
    this.videoTexture = new THREE.Texture(this.mainStore.ar.videoCanvas);
    this.videoTexture.minFilter = THREE.LinearFilter;
    this.videoTexture.magFilter = THREE.LinearFilter;
    this.videoTexture.needsUpdate = false
    const videoMaterial = new THREE.SpriteMaterial({ map: this.videoTexture, depthWrite: false, side: THREE.DoubleSide });

    // Create video plane
    this.videoSprite = new THREE.Sprite(videoMaterial);
    this.videoSprite.center.set(0.5, 0.5);

    this.videoSprite.position.set(0, 0, 1);
    this.scene2D.add(this.videoSprite);
    
    this.firstRender()
    this.mainStore.ar.videoElement.addEventListener("canplay", () => {
      this.onResize()
      this.loop()
      window.addEventListener('orientationchange', this.onPostResize)
    }, { once: true })

    return () => {
      window.removeEventListener('orientationchange', this.onPostResize)
      this.stopFlag = true
    }
  }

  private stopFlag = false
  private clock = new THREE.Clock()
  loop() {
    if (this.stopFlag) return
    const arAugmentedVision = this.mainStore.ar.track()
    const delta = this.clock.getDelta()
    if (arAugmentedVision.numObjectDetected > 0) {
      // Prepare position
      let t = arAugmentedVision.getObjectPosition(0)

      // Prepare orientation
      let R = arAugmentedVision.getObjectRotation(0)
      var l_transfMatrix = new THREE.Matrix4();
      l_transfMatrix.set(
          R[0][0], R[0][1], R[0][2], 0,
          -R[1][0], -R[1][1], -R[1][2], 0,
          -R[2][0], -R[2][1], -R[2][2], 0,
          0.0, 0.0, 0.0, 1.0
      )

      this.scene.matrix = l_transfMatrix
      this.scene.position.set(t[0], -t[1], -t[2])
      this.scene.rotation.setFromRotationMatrix(l_transfMatrix)
      this.scene.rotateX(90 * THREE.MathUtils.DEG2RAD)

      this.scene.visible = true
      for (let child of this.scene.children) {
        const mixer = child.userData.mixer as THREE.AnimationMixer
        if (!mixer) continue
        mixer.update(delta)
      }

      for (let videoTexture of (this.scene.userData.videoTextures || [])) {
        if (!videoTexture.userData.videoPlaying) continue
        videoTexture.userData.video.play()
      }

    } else {
      this.scene.visible = false
      for (let videoTexture of (this.scene.userData.videoTextures || [])) {
        videoTexture.userData.video.pause()
      }
    }

    this.videoTexture.needsUpdate = true;
    this.renderer.clear();
    this.renderer.render(this.scene2D, this.camera2D);
    this.renderer.render(this.scene3D, this.camera3D);

    requestAnimationFrame(this.loop)
  }

  firstRender() {
    this.renderer.clear();
    this.renderer.render(this.scene3D, this.camera3D);
  }

  setCanvasRef(canvas: HTMLCanvasElement | null) {
    if (canvas === null) return
    this.canvas = canvas
  }

  dispatch(type: string, args: any) {
    this.scene.dispatchEvent({ type, ...args })
  }

  onResize() {
    const video = this.mainStore.ar.videoElement
    this.camera2D.left = -video.videoWidth / 2;
    this.camera2D.right = video.videoWidth / 2;
    this.camera2D.top = video.videoHeight / 2;
    this.camera2D.bottom = -video.videoHeight / 2;
    this.camera2D.updateProjectionMatrix();

    this.camera3D.aspect = video.videoWidth / video.videoHeight;
    this.camera3D.updateProjectionMatrix();

    this.renderer.setSize(video.videoWidth, video.videoHeight, false);
    this.canvas.style.width = "100%"
    this.canvas.style.height = "100%"
    this.canvas.style.objectFit = "fill"
    setTimeout(() => {
      this.canvas.style.objectFit = "cover"
    }, 0)
    
    // Update video canvas size
    this.mainStore.ar.onResize()

    // Create video texture
    this.videoTexture = new THREE.Texture(this.mainStore.ar.videoCanvas);
    this.videoTexture.minFilter = THREE.LinearFilter;
    this.videoTexture.magFilter = THREE.LinearFilter;
    this.videoTexture.needsUpdate = true
    this.videoSprite.material.map = this.videoTexture

    this.videoSprite.scale.set(this.mirror ? -video.videoWidth : video.videoWidth, video.videoHeight, 1);
  }

  onPostResize() {
    setTimeout(() => this.onResize(), 50)
  }

  raycaster = new THREE.Raycaster()
  onCanvasClick(e: React.MouseEvent<HTMLCanvasElement>) {
    const objects = findRecursive(this.scene3D, (obj) => "onClick" in obj.userData)
    const a = (e.currentTarget.width/e.currentTarget.height) / (e.currentTarget.offsetWidth/e.currentTarget.offsetHeight)
        
    const x = (e.nativeEvent.offsetX/e.currentTarget.offsetWidth * 2 - 1) * Math.min(1/a, 1)
    const y = (-e.nativeEvent.offsetY/e.currentTarget.offsetHeight * 2 + 1) * Math.min(a, 1)

    const pointer = new THREE.Vector2(x, y)
    this.raycaster.setFromCamera(pointer, this.camera3D)
    const intersects = this.raycaster.intersectObjects(objects, true)
    if (intersects.length === 0) return

    (intersects[0].object.userData.onClick || intersects[0].object.parent?.userData.onClick)(e)
  }



}

export default RenderStore