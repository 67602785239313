import * as THREE from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { createTransparentMaterial } from 'utils/chroma-shader';
import { findRecursive } from 'utils/mesh';
import { addRoomLight } from './utils';

type Asset = THREE.Texture & GLTF
type Assets = { [key in keyof typeof assets]: Asset }

export const assets = {
  model: "./assets/all.glb",
  marakasi: "./assets/marakasi.glb",
  // mainmodel: "./assets/main.glb",

  // modelback: "./assets/modelback.glb",
  popugay: "./assets/popugay.mp4",
  box: "./assets/boxd3.glb"

}

export const assetsSign = {
  //model: 1829400  * 14
}

export const initScene = async (scene: THREE.Scene, assets: Assets) => {
  addRoomLight(scene)

  console.log(assets.model)

  // assets.mainmodel.scene.scale.set(2.5, 2.5, 2.5).multiplyScalar(2)
  // assets.mainmodel.scene.position.set(0, 0.02, 0).multiplyScalar(2)
  // assets.mainmodel.scene.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)

  assets.model.scene.scale.set(2.5, 2.5, 2.5).multiplyScalar(2)
  assets.model.scene.position.set(-1.54, -0.16, -0.1).multiplyScalar(2)
  assets.model.scene.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)

  assets.marakasi.scene.scale.set(2.5, 2.5, 2.5).multiplyScalar(2)
  assets.marakasi.scene.position.set(-1.54, -0.16, -0.1).multiplyScalar(2)
  assets.marakasi.scene.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)

  // assets.modelback.scene.scale.set(2.5, 2.5, 2.5).multiplyScalar(2)
  // assets.modelback.scene.position.set(-1.5, -0.17, -0.1).multiplyScalar(2)
  // assets.modelback.scene.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)



  assets.box.scene.scale.set(2.5, 2.5, 2.5).multiplyScalar(2.15)
  assets.box.scene.position.set(-1.55, -0.16, -0.08).multiplyScalar(2.15)
  assets.box.scene.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)
  // assets.box.scene.userData.transparent = true
  // assets.box.scene.userData.opacity = 0
  //assets.box.scene.userData.blending = MultiplyBlending 
  //var material = new THREE.MeshLambertMaterial({color: 0x00ff00, transparent: true, opacity: 0.5});


  // const material = new THREE.MeshBasicMaterial({ map: assets.box, transparent: true})
  // const assets.box = new THREE.Mesh(new THREE.PlaneGeometry(), material)
  // assets.box.scene.scale.set(2.5, 2.5, 2.5).multiplyScalar(2)
  // assets.box.scene.position.set(-1.5, -0.2, -0.1).multiplyScalar(2)
  // assets.box.scene.rotation.set(-90*THREE.MathUtils.DEG2RAD, 0, 0)


  const button = findRecursive(assets.model.scene, obj => obj.name === "video_plane")[0] as THREE.Mesh
  button.userData.onClick = () => {
    assets.popugay.userData.video.muted = true
    scene.dispatchEvent({ type: "onButtonClick" })
  }
  scene.addEventListener("onDialogClose", () => {
    assets.popugay.userData.video.muted = false
  })

  const video = findRecursive(assets.model.scene, obj => obj.name === "Plane001")[0] as THREE.Mesh
  video.material = createTransparentMaterial(assets.popugay, 0x01A701, 0.4)
  assets.popugay.userData.videoPlaying = true
  assets.popugay.userData.video.muted = false 
  assets.popugay.userData.video.loop = false 
  scene.add(assets.model.scene)
  scene.add(assets.marakasi.scene)
  // scene.add(assets.mainmodel.scene)
  // scene.add(assets.modelback.scene)
  scene.add(assets.box.scene)
  assets.model.scene.renderOrder = 5
  findRecursive(assets.box.scene, obj => obj.type === "Mesh").forEach(m => {
    const material = ((m as THREE.Mesh).material as THREE.MeshStandardMaterial)
    material.colorWrite = false
  })

  scene.userData.videoTextures = [ assets.popugay ]

  // Код ниже синхронизирует анимацию и модельку 
  const animDuration = assets.model.animations.reduce((max, val) => Math.max(max, val.duration), 0)
  let period = 0
  setInterval(() => {
    if (assets.model.scene.userData.mixer.time > period*animDuration+0.6) {
      period++
      assets.popugay.userData.video.currentTime = 0
    }
  }, 50)

}
