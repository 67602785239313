import cn from "classnames"
import { animated, config, useTransition } from "react-spring"
import styles from './style.module.sass'

type Props = {
  open: boolean, 
  children: React.ReactNode,
  onClose?: () => void,
  className?: string
}

const Dialog = ({ open, children, onClose, className }: Props) => {

  const transition = useTransition(open, {
    from: { scale: 0.5, opacity: 0 },
    enter: { scale: 1, opacity: 1 },
    leave: { scale: 0.5, opacity: 0 },
    config: { mass: 0.5, tension: 210, friction: 12 },
  })

  const onOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose && onClose()
    }
  }

  return (
    <div className={cn(styles.overlay, open && styles.active)} onClick={onOverlayClick}>
      { transition((style, open) => open && (
        <animated.div className={cn(styles.dialog, className)} style={style}>
          { children }
        </animated.div>
      ))}

    </div>
  )

}

export default Dialog