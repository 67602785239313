import { observer } from "mobx-react-lite"
import { useMainStore } from "store"
import LoaderPage from "pages/preloader"
import CameraRejectedPage from "pages/preloader/camera-rejected"
import GettingStartedPage from "pages/preloader/getting-started"
import ArPage from "pages/ar"
import NotSupportedPage from "pages/preloader/not-supported"

const App: React.FC = observer(() => {

  const { camera, loader } = useMainStore()

  if (loader.status === "not-supported") {
    return <NotSupportedPage/>
  }

  if (camera.status === "reject") {
    return <CameraRejectedPage/>
  }

  if (loader.loadStatus !== "complete") {
    return <GettingStartedPage/>
  }

  if (loader.status === 'complete') {
    return <ArPage/>
  }

  return <LoaderPage/>
})

export default App