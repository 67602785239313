import * as THREE from "three"

const vertexTransparentShader = `
varying vec2 vUv;

void main() {
    vUv = uv;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

const fragmentTransparentShader = `
#define USE_MAP true

uniform sampler2D map;
uniform vec3 color;
varying vec2 vUv;
void main(void)
{
  vec3 tColor = texture2D( map, vUv ).rgb;
  float a = (length(tColor - color) - %thres) * 12.0;
  gl_FragColor = vec4(tColor, a);
}
`

export const getTransparentShader = (color: THREE.Color, texture: THREE.Texture, thres = 0.65) => ({
  transparent: true,
  uniforms: {
    color: {
      type: 'c',
      value: { x: color.r, y: color.g, z: color.b }
    },
    map: {
      type: 't',
      value: texture
    }
  },
  vertexShader: vertexTransparentShader,
  fragmentShader: fragmentTransparentShader.replace("%thres", thres.toString())
})

export const createTransparentMaterial = (texture: THREE.Texture, color: THREE.ColorRepresentation, thres = 0.65) => {
  return new THREE.ShaderMaterial(getTransparentShader(new THREE.Color(color), texture, thres))
}