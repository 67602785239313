import styles from './style.module.sass'
import cn from 'classnames'
import img from './logo.png'

const NotSupportedPage = () => {

  const copy = () => {
    navigator.clipboard.writeText(window.location.href)
  }

  return (
    <div className={cn(styles.container, 'h')}>
      <img className={styles.image} src={img} alt="Водовоз Лого"/>
      <div>Извините, данный браузер не поддерживает AR технологию</div>
      <div className={styles.notSupportedText}>
        <div>Перейдите в Google Chrome или Safari:</div>
        <button onClick={copy}>
          { window.location.href }
        </button>
        <div className={styles.small}>(нажмите, чтобы скопировать ссылку)</div>
      </div>
    </div>
  )
}

export default NotSupportedPage
